<template>
  <b-card title="Registros">
    <app-timeline v-for="registro in historico" :key="registro._id">
      <app-timeline-item
        :variant="registro.variant"
        :title="registro.title"
        :subtitle="registro.subtitle"
        :time="registro.time"
        class="mb-2"
      />
    </app-timeline>
  </b-card>
</template>

<script>
import AppTimeline from "@core/components/app-timeline/AppTimeline.vue";
import AppTimelineItem from "@core/components/app-timeline/AppTimelineItem.vue";

export default {
  props: {
    historico: {
      type: Array,
    },
  },
  data() {
    return {
    };
  },
  components: {
    AppTimeline,
    AppTimelineItem,
  },
};
</script>

<style>
</style>
