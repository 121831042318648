<template>
  <b-card title="Registrar informação">
    <div class="form-label-group">
      <b-form-textarea
        id="textarea"
        rows="3"
        placeholder="Escreva a mensagem..."
        v-model="text"
      />
    </div>

    <b-button variant="primary" block @click="save"> Registrar </b-button>
  </b-card>
</template>

<script>
import { mapState } from "vuex";
export default {
  props: {
    imob: {
      type: Object,
    },
    createHistory: Function,
  },
  data() {
    return {
      text: "",
    };
  },
  computed: {
    ...mapState(["user"]),
  },
  methods: {
    save() {
      if (this.text === "") return;
      let subtitle = this.text.replace(/\n\r?/g, "<br/>");
      let title = `${this.user.user.name} Registrou`
      
      //save
      this.createHistory(title, subtitle);
      //reset
      this.text = ""
    },
  },
};
</script>

<style>
</style>