<template>
  <div>
    <b-card-body>
      <app-timeline>
        <app-timeline-item
          class="panel"
          :variant="defineVariant('LGPD')"
          @click="confirmBox(2, 'LGPD')"
        >
          LGPD
        </app-timeline-item>
        <app-timeline-item
          class="panel"
          :variant="defineVariant('Fornecedor Pendente')"
          @click="confirmBox(2, 'Fornecedor Pendente')"
        >
          Fornecedor Pendente
        </app-timeline-item>
        <app-timeline-item
          class="panel"
          :variant="defineVariant('Cadastro')"
          @click="confirmBox(2, 'Cadastro')"
        >
          Cadastro
        </app-timeline-item>
        <app-timeline-item
          class="panel"
          :variant="defineVariant('Rejeitado Cadastro')"
          @click="confirmBox(2, 'Rejeitado Cadastro')"
        >
          Rejeitado Cadastro
        </app-timeline-item>
        <app-timeline-item
          class="panel"
          :variant="defineVariant('Compliance')"
          @click="confirmBox(2, 'Compliance')"
        >
          Complience
        </app-timeline-item>
        <app-timeline-item
          class="panel"
          :variant="defineVariant('Rejeitado Compliance')"
          @click="confirmBox(2, 'Rejeitado Compliance')"
        >
          Rejeitado Complience
        </app-timeline-item>
        <app-timeline-item
          class="panel"
          :variant="defineVariant('Fiscal')"
          @click="confirmBox(2, 'Fiscal')"
        >
          Fiscal
        </app-timeline-item>
        <app-timeline-item
          class="panel"
          :variant="defineVariant('Rejeitado Fiscal')"
          @click="confirmBox(2, 'Rejeitado Fiscal')"
        >
          Rejeitado Fiscal
        </app-timeline-item>
      </app-timeline>
    </b-card-body>
  </div>
</template>

<script>
import AppTimeline from "@core/components/app-timeline/AppTimeline.vue";
import AppTimelineItem from "@core/components/app-timeline/AppTimelineItem.vue";

export default {
  components: {
    AppTimeline,
    AppTimelineItem,
  },
  props: {
    status: {
      type: Object,
    },
    changeStatusCaller: Function,
  },
  data() {
    return {
      imob: {},
    };
  },
  methods: {
    defineVariant(status) {
      if (this.status.name === status) {
        return "success";
      } else {
        return "secondary";
      }
    },
    confirmBox(id, name) {
      this.$bvModal
        .msgBoxConfirm(`Transferir para ${name}?`, {
          title: "Confirmar",
          okTitle: "Sim",
          cancelTitle: "Não",
        })
        .then((res) => {
          if (res) {
            this.changeStatusCaller(id, name);
            this.status = {
              id,
              name,
            };
          }
        });
    },
  },
};
</script>

<style>
.panel:hover {
  cursor: pointer;
  transform: scale(1.1);
  transition: all 0.3s;
}
</style>