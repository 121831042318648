<template>
  <b-card>
    <b-tabs pill align="center">
      <b-tab :disabled="status.id == 3 ? true : false">
        <template #title>
          <span class="d-none d-sm-inline text-center"> ANÁLISE</span>
        </template>
        <Analise :status="status" :changeStatusCaller="changeStatusCaller" />
      </b-tab>
      <b-tab :active="status.id == 2 ? true : false" :disabled="status.id == 3 ? true : false">
        <template #title>
          <span class="d-none d-sm-inline text-center"> AUSA</span>
        </template>
        <Ausa :status="status" :changeStatusCaller="changeStatusCaller" />
      </b-tab>
      <b-tab :active="status.id == 3 ? true : false">
        <template #title>
          <span class="d-none d-sm-inline text-center"> APROVAÇÃO</span>
        </template>
        <Aprovacao :status="status" :changeStatusCaller="changeStatusCaller" />
      </b-tab>
    </b-tabs>
  </b-card>
</template>

<script>
import Analise from "./StatsControl/Analise.vue";
import Ausa from "./StatsControl/Ausa.vue";
import Aprovacao from './StatsControl/Aprovacao.vue'

export default {
  components: {
    Analise,
    Ausa,
    Aprovacao
  },
  props: {
    status: {
      type: Object,
    },
    changeStatus: Function,
  },
  methods: {
    changeStatusCaller(id, name) {
      this.changeStatus(id, name);
      this.status = {
        id,
        name,
      };
    },
  },
};
</script>

<style>
</style>