<template>
  <div>
    <!-- 
      NÃO CREDENCIADO 
      -->
    <b-tabs pills>
      <!-- TAB INFORMAÇÕES -->
      <b-tab active>
        <template #title>
          <feather-icon icon="UserIcon" size="16" class="mr-0 mr-sm-50" />
          <span class="d-none d-sm-inline"> Informações</span>
        </template>
        <b-row>
          <b-col cols="12" xl="8">
            <Data-View :imob="imob" :changeStatus="changeStatus" />
            <Historico :historico="historico" />
          </b-col>
          <b-col cols="12" xl="4">
            <Stats-Control :status="imob.status" :changeStatus="changeStatus" />
            <Registro
              :imob="imob"
              :saveImob="saveImob"
              :createHistory="createHistory"
            />
          </b-col>
        </b-row>
      </b-tab>
      <!-- TAB CADASTRO -->
      <b-tab>
        <template #title>
          <feather-icon icon="EditIcon" size="16" class="mr-0 mr-sm-50" />
          <span class="d-none d-sm-inline"> Cadastro</span>
        </template>
        <Edit-perfil :imobData="imob" :saveImob="saveImob" />
      </b-tab>

      <!-- TAB CHAT -->
      <b-tab>
        <template #title>
          <div @click="getAtendimentos">
            <b-icon icon="person-badge" aria-hidden="true"></b-icon>
            <span class="d-none d-sm-inline"> Contatos</span>
          </div>
        </template>
        <Chat
          :imob="imob"
          :rooms="rooms"
          :roomsLoaded="roomsLoaded"
          :loadingRooms="loadingRooms"
          :getAtendimentos="getAtendimentos"
        />
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import DataView from "./components/DataView.vue";
import StatsControl from "./components/StatsControl.vue";
import EditPerfil from "./components/EditPeril.vue";
import Historico from "./components/Historico.vue";
import Registro from "./components/Registro.vue";
import Chat from "./components/Chat.vue";

import axios from "axios";
import { baseApiUrl } from "@/global";

import { mapState } from "vuex";
export default {
  components: {
    DataView,
    StatsControl,
    EditPerfil,
    Historico,
    Registro,
    Chat,
  },
  data() {
    return {
      imob: {},
      historico: [],
      rooms: [],
      loadingRooms: true,
      roomsLoaded: false,
    };
  },
  computed: {
    ...mapState(["user"]),
  },
  methods: {
    async getImob() {
      await axios
        .post(`${baseApiUrl}/cadastros`, {
          db: this.user.user.produto.db,
          id: this.$route.params.id,
        })
        .then((res) => {
          if (res.data.success) {
            this.imob = res.data.data;
          }
        });
    },
    createHistory(title, subtitle) {
      let now = new Date();
      let historico = {
        variant: "success",
        title,
        subtitle,
        day: Math.floor(now / 1000),
        time: `${(now.getDate() < 10 ? "0" : "") + now.getDate()}/${
          now.getMonth() + 1
        }/${now.getFullYear()} às ${now.getHours()}:${
          (now.getMinutes() < 10 ? "0" : "") + now.getMinutes()
        }`,
      };
      this.historico.unshift(historico);
      axios
        .put(`${baseApiUrl}/historico`, {
          db: this.user.user.produto.db,
          _id: this.$route.params.id,
          historico: this.historico,
        })
        .then((res) => {});
    },
    changeStatus(id, name) {
      this.imob.status = {
        id,
        name,
      };
      this.createHistory(`${this.user.user.name} modificou status para`, name);
      this.saveImob();
    },
    saveImob() {
      axios
        .put(`${baseApiUrl}/cadastros`, {
          db: this.user.user.produto.db,
          imob: this.imob,
        })
        .then((res) => {
          if (res.data.success) {
            this.$bvToast.toast(res.data.message, {
              title: "Sucesso !",
              variant: "primary",
              solid: true,
            });
          }
        });
    },
    async getHistorico() {
      await axios
        .post(`${baseApiUrl}/historico`, {
          db: this.user.user.produto.db,
          id: this.$route.params.id,
        })
        .then((res) => {
          this.historico = res.data.historico;
        });
    },
    async getAtendimentos() {
      let id = this.imob._id;
      if (!id) return;
      await axios
        .post(`${baseApiUrl}/wp/calls/byimob`, {
          id,
        })
        .then((res) => {
          this.rooms = res.data;
        });
          this.roomsLoaded = true;
          this.loadingRooms = false;
    }, // **************

  },
  mounted() {
    this.getImob();
    this.getHistorico();
    this.getAtendimentos();
  },
  sockets: {
    atualizaCall() {
      this.getAtendimentos();
      this.roomsLoaded = true;
    },
  },
};
</script>

<style>
</style>