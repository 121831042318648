<template>
  <div>
    <b-button
      variant="primary"
      class="mt-2"
      block
      :disabled="status.id == 3 ? true : false"
      @click="action(3, 'Credenciado')"
    >
      Aprovar
    </b-button>
    <b-button
      variant="danger"
      class="mt-2"
      block
      @click="action(0, 'Descartado')"
    >
      Descartar
    </b-button>
  </div>
</template>

<script>
export default {
  props: {
    status: {
      type: Object,
    },
    changeStatusCaller: Function,
  },
  methods: {
    action(id, name) {
      this.$bvModal
        .msgBoxConfirm(
          `Você realmente gostaria de mudar essa imobiliária para ${name}?`,
          {
            title: "Confirmar",
            okTitle: "Sim",
            cancelTitle: "Não",
          }
        )
        .then((res) => {
          if (res) {
            this.changeStatusCaller(id, name);
            if (id === 3) {
              axios.put(`${baseApiUrl}/imobiliarias`, {
                db: this.user.user.produto.db,
                imob: this.imob,
              });
            }
          }
        });
    },
  },
};
</script>

<style>
</style>