<template>
  <b-card>
    <b-row>
      <b-col cols="12">
        <h1 class="mt-2 -">{{ imob.name }}</h1>
        <a :href="`https://${imob.website}`" target="_blank">
          <span class="card-text"> {{ imob.website }} </span>
        </a>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        cols="12"
        md="6"
        class="d-flex justify-content-between flex-column"
      >
        <div class="d-flex justify-content-start">
          <div class="d-flex flex-column ml-1">
            <div class="my-1">
              <dl>
                <dt><b>Razão Social</b></dt>
                <dd>{{ imob.corporatename }}</dd>
                <dt><b>CNPJ</b></dt>
                <dd>
                  {{ imob.cnpj }}
                </dd>
                <dt><b>CRECI PJ</b></dt>
                <dd>{{ imob.crecipj }}</dd>
              </dl>
              <p class="m-0">
                <span class="card-text">{{ imob.adress.local }}</span>
              </p>
              <p class="m-0">
                <span class="card-text"
                  >{{ imob.adress.bairro }} - {{ imob.adress.cidade }}</span
                >
              </p>
              <p class="m-0">
                <span class="card-text"> {{ imob.adress.cep }}</span>
              </p>
            </div>
          </div>
        </div>
      </b-col>
      <b-col cols="12" md="6" class="mt-1">
        <div class="mb-1">
          <b-card class="border mb-0">
            <!-- SÓCIOS -->
            <b-tabs pills>
              <b-tab active>
                <template #title>
                  <span class="d-none d-sm-inline"> Sócio-Administrador </span>
                </template>
                <p class="my-0 mt-2"><b>Nome:</b> {{ imob.owner.name }}</p>
                <p class="my-0"><b>Telefone:</b> {{ imob.owner.phone }}</p>
                <p class="my-0"><b>E-mail:</b> {{ imob.owner.email }}</p>
              </b-tab>
              <!-- ADMINISTRADORES -->
              <b-tab v-if="imob.answerable.name">
                <template #title>
                  <span class="d-none d-sm-inline"> <b>Administrador</b> </span>
                </template>
                <p class="my-0 mt-2"><b>Nome:</b> {{ imob.answerable.name }}</p>
                <p class="my-0"><b>Telefone:</b> {{ imob.answerable.phone }}</p>
                <p class="my-0"><b>E-mail:</b> {{ imob.answerable.email }}</p>
              </b-tab>
            </b-tabs>
          </b-card>
        </div>
        <!-- CREDENCIADOS-->
        <div class="text-center mt-2">
          <h2>
            Status:
            <b-badge :variant="setStatus(imob.status)">
              {{ imob.status.name }}
            </b-badge>
          </h2>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12"> </b-col>
    </b-row>
  </b-card>
</template>

<script>
import axios from "axios";
import { baseApiUrl } from "@/global";
import { mapState } from "vuex";
export default {
  //TODO link com whatsapp
  props: {
    imob: {
      type: Object,
    },
    changeStatus: {
      type: Function,
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapState(["user"]),
  },
  methods: {
    action(id, name) {
      this.$bvModal
        .msgBoxConfirm(
          `Você realmente gostaria de mudar essa imobiliária para ${name}?`,
          {
            title: "Confirmar",
            okTitle: "Sim",
            cancelTitle: "Não",
          }
        )
        .then((res) => {
          if (res) {
            this.changeStatus(id, name);
            if (id === 3) {
              axios.put(`${baseApiUrl}/imobiliarias`, {
                db: this.user.user.produto.db,
                imob: this.imob,
              });
            }
          }
        });
    },
    setStatus(status) {
      if(status.id === 1) {
        return 'warning'
      }
      if(status.id === 2) {
        return 'info'
      }
      if(status.id === 3) {
        return 'primary'
      }
      if(status.id === 0) {
        return 'danger'
      }
      
    }
  },
};
</script>

<style>
</style>