<template>
  <b-card>
    <h1 class="my-2">Imobiliária</h1>
    <b-form>
      <b-row>
        <b-col cols="12" md="4">
          <b-form-group label="Razão Social" label-for="corporatename">
            <b-form-input v-model="imobData.corporatename" />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="4">
          <b-form-group label="Nome fantasia" label-for="full-name">
            <b-form-input id="full-name" v-model="imobData.name" />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="4">
          <b-form-group label="CNPJ" label-for="full-name">
            <b-form-input id="full-name" v-model="imobData.cnpj" />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" md="4">
          <b-form-group label="Creci" label-for="corporatename">
            <b-form-input v-model="imobData.crecipj" />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="4">
          <b-form-group label="E-mail" label-for="full-name">
            <b-form-input id="full-name" v-model="imobData.email" />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="4">
          <b-form-group label="Whatsapp" label-for="full-name">
            <b-form-input
              id="full-name"
              v-model="imobData.phone"
              v-mask="'(##)#####-####'"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" md="12">
          <b-form-group label="WebSite" label-for="corporatename">
            <b-form-input v-model="imobData.website" />
          </b-form-group>
        </b-col>
      </b-row>
      <h1 class="my-2">Endereço</h1>
      <b-row>
        <b-col cols="12" md="12">
          <b-form-group label="Endereço" label-for="corporatename">
            <b-form-input v-model="imobData.adress.local" />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" md="4">
          <b-form-group label="Cidade" label-for="">
            <b-form-input v-model="imobData.adress.cidade" />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="4">
          <b-form-group label="Bairro" label-for="full-name">
            <b-form-input id="full-name" v-model="imobData.adress.bairro" />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="4">
          <b-form-group label="CEP" label-for="full-name">
            <b-form-input id="full-name" v-model="imobData.adress.cep" />
          </b-form-group>
        </b-col>
      </b-row>
      <h1 class="my-2">Proprietário</h1>
      <b-row>
        <b-col cols="12" md="4">
          <b-form-group label="Nome" label-for="">
            <b-form-input v-model="imobData.owner.name" />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="4">
          <b-form-group label="E-mail" label-for="full-name">
            <b-form-input id="full-name" v-model="imobData.owner.email" />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="4">
          <b-form-group label="WhatsApp" label-for="full-name">
            <b-form-input
              id="full-name"
              v-model="imobData.owner.phone"
              v-mask="'(##)#####-####'"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <h1 class="my-2">Representante Comercial</h1>
      <b-row>
        <b-col cols="12" md="4">
          <b-form-group label="Nome" label-for="">
            <b-form-input v-model="imobData.answerable.name" />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="4">
          <b-form-group label="E-mail" label-for="full-name">
            <b-form-input id="full-name" v-model="imobData.answerable.email" />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="4">
          <b-form-group label="WhatsApp" label-for="full-name">
            <b-form-input
              id="full-name"
              v-model="imobData.answerable.phone"
              :options="options.phone"
              v-mask="'(##)#####-####'"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="mt-2 mx-1">
        <b-button
          variant="success"
          class="mb-1 mb-sm-0 mr-0 mr-sm-1"
          @click="saveImob()"
        >
          Salvar
        </b-button>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import { mask } from "vue-the-mask";

export default {
  directives: { mask },
  props: {
    imobData: {
      type: Object,
    },
    saveImob: Function,
  },
  data() {
    return {
      options: {
        phone: {
          phone: true,
          phoneRegionCode: "BR",
        },
      },
    };
  }
};
</script>

<style>
</style>