<template>
  <div>
    <b-card-body>
      <app-timeline>
        <app-timeline-item class="panel" :variant="defineVariant('Análise')" @click="confirmBox(1, 'Análise')">
          Análise
        </app-timeline-item>
        <app-timeline-item class="panel" :variant="defineVariant('Pendência')" @click="confirmBox(1, 'Pendência')">
          Pendência
        </app-timeline-item>
        <app-timeline-item class="panel" :variant="defineVariant('Liberado')" @click="confirmBox(1, 'Liberado')">
          Liberados
        </app-timeline-item>
        <app-timeline-item class="panel" :variant="defineVariant('Agendado')" @click="confirmBox(1, 'Agendado')">
          Agendados
        </app-timeline-item>
        <app-timeline-item class="panel" :variant="defineVariant('SOS')" @click="confirmBox(1, 'SOS')">
          SOS
        </app-timeline-item>
      </app-timeline>
    </b-card-body>
  </div>
</template>

<script>
import AppTimeline from "@core/components/app-timeline/AppTimeline.vue";
import AppTimelineItem from "@core/components/app-timeline/AppTimelineItem.vue";

export default {
  components: {
    AppTimeline,
    AppTimelineItem,
  },
  props: {
    status: {
      type: Object,
    },
    changeStatusCaller: Function,
  },
  data() {
    return {
      imob: {},
    };
  },
  methods: {
    defineVariant(status) {
      if (this.status.name === status) {
        return "success";
      } else {
        return "secondary";
      }
    },
    confirmBox(id, name) {
      this.$bvModal
        .msgBoxConfirm(`Transferir para ${name}?`, {
          title: "Confirmar",
          okTitle: "Sim",
          cancelTitle: "Não",
        })
        .then((res) => {
          if (res) {
            this.changeStatusCaller(id, name);
            this.status = {
              id,
              name,
            };
          }
        });
    },
  },
};
</script>

<style>
.panel:hover {
  cursor: pointer;
  transform: scale(1.1);
  transition: all 0.3s;
}
</style>